import { 
    defineComponent, 
    onMounted, 
    reactive, 
    ref,
    getCurrentInstance, 
    ComponentInternalInstance, 
} from 'vue'
import {  useRoute, useRouter } from 'vue-router'
import { 
    jobDetail,
    closeJob, 
    openJob 
 } from '@/api/index'
 import { Dialog } from 'vant';
 import openMini from '@/components/openMini.vue'
 import { SET_SIGNATURE } from '@/utils/constant'


 const info_pic = require('@/assets/img/info.png')

import './index.less'

export default defineComponent({
    name: 'Info',
    components: {
        openMini
    },
    setup () {
        onMounted(() => {
            const { id } = route.query
            if (id) {
                _jobDetail(id)
            } 
            _initJS()
        })
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const route = useRoute()
        const signature = ref(null)
        
        const state: any = reactive({
            form: { } ,//表单
            hights: [],
            roleMoney: {
                money: 0,
                money2: 0,
                money3: 0
            }
        })

        const _initJS = async () => {
            const local: any = localStorage.getItem(SET_SIGNATURE)
            if (local) {
                signature.value = local
            }
        }

        const _initForm = (data: any) => {
            state.form = { ...data }
            state.citysCode = data.districtId
            state.form.negotiable = [data.negotiable]
            console.log(state.form.negotiable, '打印了神恶魔...')
        }

        const _jobDetail = async(id: any) => {
            const res: any = await jobDetail({data: id})
            _initForm(res.data)
            state.hights = _convertHightlit(res.data.highlights)
            _roleMoney(res.data.recommendRewardRole)
            console.log(res, '职位详情.')
        }
       
        const _convertHightlit = (str: string) => {
            if (!str)return []
            const array = JSON.parse(str)
            return array
        }

        const _roleMoney = (str: string) => {
            if (!str)return ''
            const obj = JSON.parse(str)
            let money = 0
            if (obj[200] && obj[300]) {
                money = Number(obj[200]) + Number(obj[300])
            } else {
                money = Number(obj[400])
            }
            state.roleMoney = {
                money: obj[0],
                money2: obj[200],
                money3: money
            }
        }

        const onOpenEditePage = (id: number) => {
            router.push({path: '/publish', query: { id: id }})
        }

         // 开启职位
         const handleOpenJob = async (id: number) => {
            const res: any = await openJob({data: id})
            if (res.code === 0) {
                proxy.$toast('职位开启成功!')
                router.push({path: '/job' })
                return  
            } else {
                proxy.$toast('职位开启失败!')
            }
        }

        // 关闭职位
        const handleCloseJob = async (id: number) =>{
            const res: any = await closeJob({data: id})
            if (res.code === 0) {
                proxy.$toast('职位关闭成功!');
                router.push({path: '/job' })
                return 
            } else {
                proxy.$toast('职位关闭失败!');
            }
        }

          // 开启职位后，求职者可以投递简历
        //打开关闭简历弹框
        const onOpenDialog= (num: number,id: number): void => {
            //关闭简历弹框
            const closeUI = () => {
                return (
                    <div>
                        <img class="w64" src={info_pic} />
                        <div class="info-font">
                            { num === 0 ? '关闭职位后，将无法接收简历' : '开启职位后，求职者可以投递简历' }
                         </div>
                    </div>
                )
            }

            Dialog.confirm({
                message: closeUI,
                closeOnClickOverlay: true,
            }).then(() => {
                num  === 1 ? handleOpenJob(id) : handleCloseJob(id)
                console.log('dayinl')
            }).catch(() => {
                // on cancel
                console.log('cancel')
            });
        }

        return () => (
            <div class="publish-page">
                <div class="header-box flex-box justify-content-between">
                    <div>职位详情</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="container-box">
                    <div class="job-info-box">
                        <div class="flex-box">
                            <div class="job-name">{ state.form.name }</div>
                            <div class="money-box">{ state.form.salaryMin } - { state.form.salaryMax }/月</div>
                        </div>
                        <div class="flex-box">
                            <div class="work-box">
                                { state.form.workExperienceName == '不限' ? '经验不限' : state.form.workExperienceName } |
                                { state.form.education == '不限' ? '学历不限' : state.form.education }
                            </div>
                            <div class="time-box">
                                发布时间：{ state.form.createTime }
                            </div>
                        </div>
                    </div>
                    <div class="mt37">
                        <span class="label-item">职位类别：</span>
                        <span class="label-value">{ state.form.function }</span>
                    </div>
                    <div class="mt20">
                        <span class="label-item">工作地点：</span>
                        <span class="label-value">{ state.form.address }</span>
                    </div>
                    <div class="mt20">
                        <span class="label-item">提成比例：</span>
                        <span class="label-value">{ !state.form.commission ? '无' : state.form.commission }</span>
                    </div>
                    <div class="mt20">
                        <span class="label-item">职位亮点：</span>

                        {
                            state.hights.map((item: any, index: number) => {
                                return <span class="hight-item" key={index}>{item}</span>
                            })
                        }
                    </div>
                    <div class="mt20">
                        <div class="label-item">职位描述：</div>
                        <van-field
                            class="textarea-box"
                            v-model={ state.form.description }
                            rows="1"
                            autosize
                            readonly
                            type="textarea"
                        />
                    </div>    
                    <div style={{ marginBottom: !state.form.recommendReward ? '60px' : '' }}>
                        <span class="label-item">内推奖金设置：</span>
                        <span class="label-value" style={{ color: !state.form.recommendReward ? '#FE4A49' : '' }}>{ !state.form.recommendReward ? '未设置' : '' }</span>
                        {
                            state.form.recommendReward ? (
                                <div class="push-money-box">
                                    <div class="employee-box">员工推荐的求职者：</div>
                                    <ul class="line-box">
                                            <li>
                                                <span class="sqaure-box"><van-icon name="success" /></span>
                                                <div class="first-block">
                                                 推荐成功，奖励:<span class='money-item'>{ state.roleMoney.money }</span>元
                                                 <div class='yaoyue-box'>(邀约面试)</div>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="middel-sqaure"><van-icon name="success" /></span>
                                                面试通过，奖励:<span class='money-item'>{ state.roleMoney.money2 }</span>元
                                               
                                            </li>
                                            <li>
                                                <span class="last-sqaure"><van-icon name="success" /></span>
                                                <div class="last-block">
                                                    实际到岗，奖励:<span class='money-item'>{ state.roleMoney.money3 }</span>元
                                                </div>
                                            </li>
                                        </ul>
                                </div>
                            ) : null
                        }
                        <div class="koukuan-info-box">
                            <div class='header'>
                                <img src='https://img.fanwoon.com/redtishi%402x.png' alt='' />
                                <span>内推奖金说明：</span>
                            </div>
                            <div class='intro'>
                                设置内推奖金后，系统不会自动扣除余额，需要您手动确认后才会扣除，请放心使用产品
                            </div>
                        </div> 
                    </div>   
                    
                </div>

                <div class="footer-box">
                    {
                        state.form.status !== 1 ? (  <button class="button-item btn3 w684" onClick={ () => onOpenDialog(1, state.form.id) }>开启</button>) : (
                           <div class="footer-container-box">
                                <button class="button-item btn1" onClick={ () => onOpenDialog(0, state.form.id) }>关闭</button>
                                <openMini type={2} msg='分享' signature={signature.value} />
                                <button class="button-item btn3" onClick={() => onOpenEditePage(state.form.id)}>编辑</button>
                           </div>
                        )
                    }
                </div>
            </div>
        )
    }
})